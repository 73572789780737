<template>
	<v-row no-gutter dense class="product R-Layout d-flex flex-column">
		<div class="d-flex justify-start pb-10" style="margin-top: -40px">
			<div v-on:click="changeTab('account')">
				<app-back-btn />
			</div>
		</div>

		<v-row>
			<v-col cols="12" md="9">
				<v-card tag="div" elevation="0" outlined color="#F7F9FC">
					<p class="my-heading ma-0" style="padding-bottom: 48px">Choose your categories</p>

					<v-row class="ma-0">
						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.lumber }"
							v-on:click=";(category.lumber = !category.lumber), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Lumber']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Lumber</span>
						</div>

						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.electric }"
							v-on:click=";(category.electric = !category.electric), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Electric']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Electric</span>
						</div>

						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.plumbing }"
							v-on:click=";(category.plumbing = !category.plumbing), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Plumbing']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Plumbing</span>
						</div>

						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.masonry }"
							v-on:click=";(category.masonry = !category.masonry), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Masonry']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Masonry</span>
						</div>

						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.general }"
							v-on:click=";(category.general = !category.general), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['ToolBoxes']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">General</span>
						</div>
						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.landscape }"
							v-on:click=";(category.landscape = !category.landscape), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Landscape']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Landscape</span>
						</div>
					</v-row>

					<v-row dense class="align-center justify-center p-0 custom-error" :style="{ display: ShowMessage }">
						<v-col cols="12">{{ this.error_message }}</v-col>
					</v-row>
					<v-row dense v-if="$store.state.auth.error != ''" class="align-center justify-center p-0 custom-error">
						<v-col cols="12">{{ this.$store.state.auth.error }}</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="d-flex align-self-end justify-end pb-0" cols>
				<!-- <v-btn class="my-outline-btn mr-5" depressed outlined @click="skipSettings">Skip settings</v-btn> -->

				<v-btn class="my-p-btn my_elevation" id="my_elevation" depressed @click="next" :disabled="!valid">Next</v-btn>
			</v-col>
		</v-row>
	</v-row>
</template>

<script>
import { setUser, productCategory } from '../../services/registerservice'
require('../../scss/product.scss')
export default {
	data() {
		return {
			show: true,
			valid: true,
			error_message: '',
			show_message: 'none',
			category: {
				general: false,
				electric: false,
				lumber: false,
				masonry: false,
				plumbing: false,
				landscape: false,
			},
			category_data: {
				category_id: [],
				company_id: '',
				user_id: '',
			},
		}
	},

	beforeRouteLeave(to, from, next) {
		this.storeVuex()
		next()
	},

	async created() {
		if (this.$store.state.register.register_user.user_id === '' || this.$store.state.register.company.company_id === '') {
			await setUser()
			this.myCategory()
		} else {
			this.myCategory()
		}
	},

	computed: {
		ShowMessage() {
			return (this.show_message = !this.error_message ? 'none' : 'block')
		},
	},

	methods: {
		myCategory() {
			let user = JSON.parse(JSON.stringify(this.$store.state.register.register_user))
			let company = JSON.parse(JSON.stringify(this.$store.state.register.company))

			let category = JSON.parse(JSON.stringify(this.$store.state.register.category.category_id))

			this.category_data.user_id = user.user_id
			this.category_data.company_id = company.company_id

			if (category != null) {
				if (category.includes(1)) {
					this.category.lumber = true
				}
				if (category.includes(2)) {
					this.category.electric = true
				}
				if (category.includes(3)) {
					this.category.plumbing = true
				}
				if (category.includes(4)) {
					this.category.masonry = true
				}
				if (category.includes(5)) {
					this.category.general = true
				}
				if (category.includes(6)) {
					this.category.landscape = true
				}
			}
		},

		storeVuex() {
			this.category.lumber === true ? this.category_data.category_id.push(1) : ''
			this.category.electric === true ? this.category_data.category_id.push(2) : ''
			this.category.plumbing === true ? this.category_data.category_id.push(3) : ''
			this.category.masonry === true ? this.category_data.category_id.push(4) : ''
			this.category.general === true ? this.category_data.category_id.push(5) : ''
			this.category.landscape === true ? this.category_data.category_id.push(6) : ''
			this.$store.state.register.category.category_id = JSON.stringify(this.category_data.category_id)
		},

		changeTab(tab_name) {
			this.errorMessages = ''
			this.$store.commit('errorMessage', '')
			this.$store.commit('registerTab', tab_name)
		},

		async next() {
			if (
				this.category.general === true ||
				this.category.electric === true ||
				this.category.lumber === true ||
				this.category.plumbing === true ||
				this.category.masonry === true ||
				this.category.landscape === true
			) {
				this.error_message = ''
				this.$store.commit('errorMessage', '')

				this.category.lumber === true ? this.category_data.category_id.push(1) : ''
				this.category.electric === true ? this.category_data.category_id.push(2) : ''
				this.category.plumbing === true ? this.category_data.category_id.push(3) : ''
				this.category.masonry === true ? this.category_data.category_id.push(4) : ''
				this.category.general === true ? this.category_data.category_id.push(5) : ''
				this.category.landscape === true ? this.category_data.category_id.push(6) : ''
				this.$store.state.register.category.category_id = JSON.parse(JSON.stringify(this.category_data.category_id))
				let data = await productCategory(this.category_data.user_id, this.category_data)

				if (data.data) {
					this.$store.commit('categorycomplete')
					this.changeTab('authorize')
				}
			} else {
				this.valid = false
				this.error_message = 'Please select minimum one category'
			}
		},

		// skipSettings() {
		// 	this.$router.push({ path: "/authorize" });
		// }
	},
}
</script>

<style lang="scss"></style>
